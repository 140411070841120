<template>
  <b-row>

    <b-col md="8">
      <b-card ref="A">

        <b-tabs justified
                v-model="tabIndex"
                ref="B">

          <!--          Opportunity-->
          <b-tab>
            <template #title>
              <div
                  v-b-tooltip.hover.top="capitalize($t('opportunity'))"
                  class="d-flex">
                <icon icon="paper-plane"/>
                <span class="text-truncate ml-50">{{ capitalize($t('opportunity')) }}</span>
              </div>
            </template>

            <b-card-text>
              <opportunity :workflow="workflow"/>
            </b-card-text>
          </b-tab>

          <!--          Calendar-->
          <b-tab ref="C">
            <template #title>
              <div
                  v-b-tooltip.hover.top="capitalize($tc('calendar'))"
                  class="d-flex">
                <icon icon="calendar-alt"/>
                <span class="text-truncate ml-50">{{ capitalize($tc('calendar')) }}</span>
              </div>
            </template>

            <b-card-text>
              <calendar
                  v-if="workflow"
                  :withToolbar="false"
                  @newCalendarDates="calendarDatesChange"
                  ref="calendar"
              />
            </b-card-text>
          </b-tab>

          <!--          Orders-->
          <b-tab>
            <template #title>
              <div
                  v-b-tooltip.hover.top="capitalize($tc('order', 2))"
                  class="d-flex">
                <icon icon="shopping-cart"/>
                <span class="text-truncate ml-50">{{ capitalize($tc('order', 2)) }}</span>
              </div>
            </template>

            <b-card-text>
              <orders :workflow="workflow"/>
            </b-card-text>
          </b-tab>

          <!--          Expenses-->
          <b-tab>
            <template #title>
              <div
                  v-b-tooltip.hover.top="capitalize($tc('expense', 2))"
                  class="d-flex">
                <icon icon="money-bill-wave"/>
                <span class="text-truncate ml-50">{{ capitalize($tc('expense', 2)) }}</span>
              </div>
            </template>

            <b-card-text>
              <incoming-invoices
                  :workflow="workflow"/>
            </b-card-text>
          </b-tab>

          <!--          Offers-->
          <b-tab>
            <template #title>
              <div
                  v-b-tooltip.hover.top="capitalize($tc('offer', 2))"
                  class="d-flex">
                <icon icon="file-invoice"/>
                <span class="text-truncate ml-50">{{ capitalize($tc('offer', 2)) }}</span>
              </div>
            </template>

            <b-card-text>
              <offers :workflow="workflow"/>
            </b-card-text>
          </b-tab>

          <!--          Outgoing invoices-->
          <b-tab>
            <template #title>
              <div
                  v-b-tooltip.hover.top="capitalize($tc('invoice', 2))"
                  class="d-flex">
                <icon icon="euro-sign"/>
                <span class="text-truncate ml-50">{{ capitalize($tc('invoice', 2)) }}</span>
              </div>
            </template>

            <b-card-text>
              <outgoing-invoices :workflow="workflow"/>
            </b-card-text>
          </b-tab>

        </b-tabs>

      </b-card>

      <indicators
          v-if="tabIndex == 0 && workflow && isMounted"
          :workflow="workflow"
      />


    </b-col>

    <b-col md="4">
      <tasks
          v-if="workflow"
          :tasks="workflow.tasks"
          @updateCalendar="updateCalendar"/>

      <monitoring
          v-if="workflow"
          :activities="workflow.activities"
          :monitoring="workflow._monitoring"
          @doneActivity="doneActivity"
          @submitValidatedActivity="submitValidatedActivity"
          @cancelPlanifiedActivity="cancelPlanifiedActivity"
          @submitValidatedNote="submitValidatedNote"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import mixinWorkflows from './mixinWorkflows'
import Opportunity from './components/opportunity/Opportunity'
import Orders from './components/order/Orders'
import IncomingInvoices from './components/incomingInvoices/IncomingInvoices'
import Offers from './components/offers/Offers'
import OutgoingInvoices from './components/outgoingInvoices/OutgoingInvoices'
import Calendar from '@/components/home/calendar/Calendar'
import Monitoring from '@/components/monitoring/Monitoring'
import Indicators from './components/indicators/Indicators'
import Tasks from '../../components/tasks/Tasks'

export default {
  components: {
    OutgoingInvoices,
    Opportunity,
    Orders,
    IncomingInvoices,
    Offers,
    Calendar,
    Monitoring,
    Indicators,
    Tasks
  },
  mixins: [mixinWorkflows],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const tabIndex = ref(0)
    const calendar = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    // const workflow = computed(() => {
    //   return store.getters['workflow/getWorkflow'](router.history.current.params.workflowId)
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(tabIndex, (val) => {
      if (val == 1) {
        updateCalendar()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateCalendar = () => {
      // console.log(calendar.value)
      calendar.value.$refs.calendar.refetchEvents()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      tabIndex,
      calendar,

      // Computed

      // Methods
      updateCalendar,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>