<template>
  <b-row>
    <b-col md="6">

      <b-card
          class="card-transaction"
          no-body
      >

        <b-card-body>

          <content-document
              v-for="(document, index) in taskEvent.documents"
              :document="document"
              @delete="deleteDocument(index)"
          />

        </b-card-body>
      </b-card>

    </b-col>

    <b-col
        md="6"
        v-if="!isDisabled"
    >
      <field-file
          :singleFile.sync="newDocument"
          :documentType="'taskEvent'"
          :resetAfterUploaded="true"
          :disabled="isDisabled"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import ContentDocument from '@/components/card/components/DocumentContent'
import FieldFile from '@/components/input/File'

export default {
  components: {
    ContentDocument,
    FieldFile
  },
  props: {
    taskEvent: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const newDocument = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newDocument, (val) => {
      // console.log(val)
      if (val != null) {
        props.taskEvent.documents.splice(props.taskEvent.documents.length, 0, val)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const deleteDocument = (index) => {
      props.taskEvent.documents.splice(index, 1)
    }

    const download = (documentToDownload = null) => {
      console.log(documentToDownload)
      // if (documentToDownload == null) {
      //   // Purchase order
      //   emit('downloadOrder', props.order)
      // } else {
      //   let link = document.createElement('a')
      //   link.setAttribute('target', '_blank')
      //   link.href = documentToDownload.url
      //   link.click()
      // }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      newDocument,

      // Computed

      // Methods
      deleteDocument,
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>