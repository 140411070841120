import { ref, computed, watch } from '@vue/composition-api'
import { useTasks } from './useTasks'
import { useEvents } from '../home/calendar/useEvents'
import { getRoute } from '@/utils/utils'
import { capitalize } from '../../utils/filter'

import LineTask from './components/_lineTask'
import ModalTask from '@/components/prompt/Task'
import ModalEvent from '@/components/prompt/Event'
import i18n from '@/libs/i18n'
import store from '@/store'

export default {
  components: {
    LineTask,
    ModalTask,
    ModalEvent,
  },
  props: {
    tasks: {
      type: Array,
      default: () => []
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const newTask = ref(null)
    const currentTask = ref(null)
    const taskModalShow = ref(false)
    const taskModalTitle = ref(i18n.t('NewTask'))

    const eventModalShow = ref(false)
    const eventModalTitle = ref(i18n.t('NewEvent'))
    const currentEvent = ref({})
    const submitEventFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const completedTasks = computed(() => {
      return props.tasks.filter(t => t.isDone)
    })

    const unCompletedTasks = computed(() => {
      return props.tasks.filter(t => !t.isDone)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(taskModalShow, (val) => {
      if (val == false) {
        resetTask()
      } else {
        if (currentTask.value.id != null) {
          taskModalTitle.value = i18n.t('EditTask')
        }
      }
    })

    watch(eventModalShow, () => {
      if (eventModalShow.value == false) {
        resetEvent()
      }
    })

    watch(currentTask, () => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        currentTask.value.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      submitValidatedTask,
      removeTask,
    } = useTasks()

    const {
      submitValidatedEvent,
    } = useEvents()

    const resetTask = () => {
      taskModalTitle.value = i18n.t('NewTask')
      newTask.value = JSON.parse(JSON.stringify(store.getters['task/getEmptyTask']))
      currentTask.value = JSON.parse(JSON.stringify(store.getters['task/getEmptyTask']))

      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        newTask.value.workflow = workflow.id
        currentTask.value.workflow = workflow.id
      }
    }

    const resetEvent = () => {
      currentEvent.value = JSON.parse(JSON.stringify(store.getters['event/getEmptyEvent']))
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        currentEvent.value.workflow = workflow.id
      }
    }

    const populateWorkflow = (task) => {
      if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

        task.workflow = {
          id: workflow.id,
          opportunity: workflow.opportunity
        }
      }
    }

    const submitValidatedTaskLocal = (task = null) => {
      populateWorkflow(task)

      if (task != null && 'title' in task) {
        // console.log(task)
        let currentTask = JSON.parse(JSON.stringify(task))
        submitValidatedTask(currentTask)
          .then(response => {

            if (getRoute().name == 'Workflow view') {
              let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

              if ('id' in currentTask) {
                let index = workflow.tasks.findIndex(i => i.id == response.data.id)

                if (response.data.workflow == null) {
                  workflow.tasks.splice(index, 1)
                } else {
                  workflow.tasks.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
                }
              } else {
                workflow.tasks.push(response.data)
              }
            }

            resetTask()
          })
        task = null
      } else {
        if (
          Object.keys(currentTask.value).length <= 1 &&
          'title' in newTask.value &&
          newTask.value.title != null) {
          // console.log(newTask.value)

          submitValidatedTask(newTask.value)
            .then(response => {
              resetTask()
            })
        } else if (
          Object.keys(currentTask.value).length > 1 &&
          'title' in currentTask.value
        ) {
          // console.log(currentTask.value)

          submitValidatedTask(currentTask.value)
            .then(response => {

              if (getRoute().name == 'Workflow view') {
                let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
                // console.log(currentTask.value)

                if ('id' in currentTask.value) {
                  let index = workflow.tasks.findIndex(i => i.id == currentTask.value.id)

                  if (response.data.workflow == null) {
                    workflow.tasks.splice(index, 1)
                  } else {
                    workflow.tasks.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
                  }
                } else {
                  workflow.tasks.push(response.data)
                }
              }
              taskModalShow.value = false

            })
        }
      }
    }

    const deleteTaskLocal = (task) => {
      removeTask(task)
        .then(() => {
          if (getRoute().name == 'Workflow view') {
            let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)

            if ('id' in task) {
              let index = workflow.tasks.findIndex(i => i.id == task.id)
              workflow.tasks.splice(index, 1)

            }
          }
        })
    }

    const submitValidatedEventLocal = () => {

      submitValidatedEvent(currentEvent.value)
        .then(response => {
          emit('updateCalendar')

          eventModalShow.value = false

          // if (getRoute().name == 'Workflow view') {
          //   let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
          //   response.data.workflow = response.data.workflow.id
          //
          //   if ('id' in currentEvent.value) {
          //     let index = workflow.events.findIndex(i => i.id == currentEvent.value.id)
          //     workflow.events.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
          //   } else {
          //     workflow.events.push(response.data)
          //   }
          //
          // }
        })
    }

    // const createTask = () => {
    //   console.log(currentTask.value)
    // }
    //
    // const updateTask = updatedTask => {
    //   console.log(updatedTask)
    // }

    const addTime = task => {
      // console.log('add time')
      // console.log(task)
      // populateWorkflowToTask(task)
      resetEvent()
      currentEvent.value.title = task.title
      currentEvent.value.notes = task.notes
      currentEvent.value.steps = JSON.parse(JSON.stringify(task.steps))
      // Todo : cloner documents

      if ('workflow' in task && task.workflow != null) {
        currentEvent.value.workflow = task.workflow.id

        if ('phase' in task && task.phase != null) {
          currentEvent.value.phase = task.phase.id
        }
      } else if (getRoute().name == 'Workflow view') {
        let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
        currentEvent.value.workflow = workflow.id
      }

      // console.log(currentEvent.value)

      eventModalShow.value = true
    }

    const viewMore = task => {
      currentTask.value = JSON.parse(JSON.stringify(task))
      taskModalShow.value = true
    }

    // const populateWorkflowToTask =(task) => {
    //   if (getRoute().name == 'Workflow view') {
    //     // console.log(store.getters['workflow/getWorkflow'](getRoute().params.workflowId))
    //     let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
    //     task.workflow = workflow.id
    //   }
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetTask()
    // resetEvent()

    return {
      // Components
      capitalize,

      // Data
      newTask,
      currentTask,
      taskModalShow,
      taskModalTitle,
      eventModalShow,
      eventModalTitle,
      currentEvent,
      submitEventFormRef,

      // Computed
      completedTasks,
      unCompletedTasks,

      // Methods
      submitValidatedTaskLocal,
      submitValidatedEventLocal,
      deleteTaskLocal,
      // createTask,
      // updateTask,
      addTime,
      viewMore,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}