<template>
  <div>
    <search-filter
        :items="incomingInvoices"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :show-past-years-filter="true"
        :allowAddByUpload="true"
        :isAddButtonsLoading="isCompanyLoading"
        @columnClick="selectIncomingInvoice($event)"
        @addItem="addIncomingInvoice"
        @addItemByUpload="addIncomingInvoiceByUpload"
    >
      <!--  =========================== -->
      <!--  ===    Results infos    === -->
      <!--  =========================== -->
      <template #resultInfos="{items, isLoading}">
        <icon
            v-if="isLoading"
            icon="spinner"
            :spin="true"
            class="text-warning"
        />

        ({{ items.length }})
        <span>{{ currency(getPreTaxAmounts(items)) }} HT / {{ currency(getTotalAmounts(items)) }} TTC</span>
      </template>


      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-incoming-invoice
            :incoming-invoice="item"
            @click="selectIncomingInvoice(item)"
            @deleteIncomingInvoice="deleteIncomingInvoiceAlert(item)"
        />
      </template>


      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Column type-->
      <template #listView_cell_type="{item}">
        <icon
            v-b-tooltip.hover.left="capitalize(item.type=='toll'?$t('transport'):(item.type=='default'?$t('generalExpenses'):$t(item.type)))"
            :icon="getIncomingInvoiceIconType(item).icon"
            :library="getIncomingInvoiceIconType(item).library?getIncomingInvoiceIconType(item).library:'fas'"
        />
      </template>

      <!--      Column status-->
      <template #listView_head_status="{item}">
        <icon icon="chart-line"/>
      </template>
      <template #listView_cell_status="{item}">
        <b-avatar
            :id="`invoice-row-${item.id}`"
            size="32"
            :variant="`light-${getIncomingInvoiceIconStatus(item).variant}`"
        >
          <icon
              :icon="getIncomingInvoiceIconStatus(item).icon"
              :library="getIncomingInvoiceIconStatus(item).library?getIncomingInvoiceIconStatus(item).library:'fas'"
          />
        </b-avatar>
        <b-tooltip
            :target="`invoice-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ capitalize($t(getIncomingInvoiceStatus(item))) }}
          </p>
        </b-tooltip>
      </template>

      <!--      Column payment status-->
      <template #listView_head__paymentStatus="{item}">
        <icon icon="cash-register"/>
      </template>
      <template #listView_cell__paymentStatus="{item}">
        <b-badge
            v-if="item._paymentStatus != null"
            pill
            :variant="displayPaymentStatus(item._paymentStatus).variant"
        >
          {{ displayPaymentStatus(item._paymentStatus).value }}
        </b-badge>
      </template>

      <!--      Column accountant downloaded-->
      <template #listView_head_accountantDownloaded="{item}">
        <icon v-b-tooltip.hover.top="capitalize($t('DownloadedForAccountant'))"
              icon="copy"/>
      </template>
      <template #listView_cell_accountantDownloaded="{item}">
        <span v-if="item.lastExport != null">
          {{ item.lastExport|moment('L') }}
        </span>
        <span v-else>-</span>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Edit -->
          <button-edit
              @click.native.stop="selectIncomingInvoice(item)"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadIncomingInvoice(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              right
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Add payment-->
            <b-dropdown-item
                @click.stop="addPayment('incomingInvoice', item)"
            >
              <icon
                  icon="cash-register"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('addPayment')) }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                v-if="item.isArchived == false"
                @click.stop="archivateIncomingInvoiceLocal(item, true)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Un archivate-->
            <b-dropdown-item
                v-if="item.isArchived == true"
                @click.stop="archivateIncomingInvoiceLocal(item, false)"
            >
              <icon
                  icon="box-open"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Unarchive')) }}</span>
            </b-dropdown-item>

            <!--          Duplicate -->
            <b-dropdown-item
                @click.stop="duplicateIncomingInvoice(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                @click.stop="deleteIncomingInvoiceAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>

          </b-dropdown>

        </div>
      </template>

    </search-filter>


    <!-- modal outgoing invoice by upload-->
    <modal-upload-incoming-invoice
        :singleFile.sync="newOutgoingInvoiceFile"
        :documentType="'incomingInvoice'"
        :title="capitalize($tc('invoice'))"
        :isOpen.sync="outgoingInvoiceByUploadModalShow"
    />


    <!-- modal incoming invoice-->
    <modal-incoming-invoice
        :incomingInvoice.sync="currentIncomingInvoice"
        :title="incomingInvoiceModalTitle"
        :isOpen.sync="incomingInvoiceModalShow"
        @submitValidatedIncomingInvoice="submitValidatedIncomingInvoiceLocal"
    />

    <!-- modal payment-->
    <modal-payment
        :payment.sync="currentPayment"
        :title="paymentModalTitle"
        :isOpen.sync="paymentModalShow"
        :absoluteDisplay="true"
        @submitValidatedPayment="submitValidatedPaymentLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinIncomingInvoices } from '../../../views/purchases/incomingInvoices/mixinIncomingInvoices'
import { useIncomingInvoices } from '@/views/purchases/incomingInvoices/useIncomingInvoices'
import { mixinPayments } from '../../../mixins/mixinPayments'
import { displayPaymentStatus, getRoute } from '../../../utils/utils'

import SearchFilter from '../../searchFilterV2/SearchFilter'
import CardIncomingInvoice from '@/components/card/IncomingInvoice'
import i18n from '@/libs/i18n'
import ButtonDownload from '@/components/button/Download'
import ButtonEdit from '@/components/button/Edit'
import ButtonDropdown from '@/components/button/Dropdown'
import router from '@/router'
import store from '@/store'
import { capitalize } from '../../../utils/filter'

export default {
  components: {
    SearchFilter,
    CardIncomingInvoice,
    ButtonDownload,
    ButtonEdit,
    ButtonDropdown,
  },
  mixins: [mixinIncomingInvoices, mixinPayments],
  props: {
    incomingInvoices: {
      type: Array,
      default: () => []
    },
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('waitingForPayment')),
        key: '_filter_isWaitingForPayment',
      },
      {
        text: capitalize(i18n.tc('expenseAccount')),
        key: '_filter_isExpense_account',
      },
      {
        text: capitalize(i18n.tc('archived')),
        key: '_filter_isArchived',
      },
      {
        text: capitalize(i18n.t('active')),
        key: '_filter_isActive',
        selected: getRoute().name == 'Workflow view'?false:true
      },
      {
        text: capitalize(i18n.t('incompletePayment')),
        key: '_filter_isPaymentIncomplete',
      },
      {
        text: capitalize(i18n.t('completePayment')),
        key: '_filter_isPaymentComplete',
      },
      {
        text: capitalize(i18n.t('paymentByBankTransfert')),
        key: '_filter_isBankTransfertPayment',
      },
      {
        text: capitalize(i18n.t('paymentByDirectDebit')),
        key: '_filter_isDirectDebitPayment',
      },
      {
        text: capitalize(i18n.t('paymentByCheck')),
        key: '_filter_isCheckPayment',
      },
      {
        text: capitalize(i18n.t('notForwardedToAccountant')),
        key: '_filter_isNotForwardedToAccountant',
      },
      {
        text: capitalize(i18n.t('generalExpenses')),
        key: '_filter_isGeneralExpenseType',
      },
      {
        text: capitalize(i18n.t('transport')),
        key: '_filter_isTollType',
      },
      {
        text: capitalize(i18n.t('accommodation')),
        key: '_filter_isAccommodationType',
      },
      {
        text: capitalize(i18n.t('catering')),
        key: '_filter_isCateringType',
      },
      {
        text: capitalize(i18n.t('production')),
        key: '_filter_isProductionType',
      },
    ])

    const columns = ref([
      {
        display: '#',
        key: 'billNumber',
        sortable: true,
        maxWidth: '100px'
      },
      {
        display: capitalize(i18n.t('billingDate')),
        key: 'billingDate',
        isDate: true,
        sortable: true
      },
      {
        display: capitalize(i18n.t('paymentDate')),
        key: 'paymentDate',
        isDate: true,
        sortable: true
      },
      {
        display: capitalize(i18n.t('type')),
        key: 'type',
        sortable: true
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: true
      },
      {
        display: capitalize(i18n.t('paymentStatus')),
        key: '_paymentStatus',
        sortable: true
      },
      {
        display: capitalize(i18n.t('supplier')),
        key: 'supplierCompany',
        sortable: true
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true
      },
      {
        display: capitalize(i18n.t('totalAmount')),
        key: 'totalAmount',
        sortable: true
      },
      {
        display: capitalize(i18n.t('accountantDownloaded')),
        key: 'accountantDownloaded',
        sortable: true
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const {
      getIncomingInvoiceStatus,
      getIncomingInvoiceIconStatus,
      getIncomingInvoiceIconType,
    } = useIncomingInvoices()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      displayPaymentStatus,

      // Data
      sortOptions,
      columns,

      // Computed

      // Methods
      getIncomingInvoiceStatus,
      getIncomingInvoiceIconStatus,
      getIncomingInvoiceIconType,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>