<template>
  <div>
    <step
        v-for="(step, index) in steps"
        :step="step"
        :index="index"
        :is-disabled="isDisabled"
    />

    <step
        :step="newStep"
        :index="(steps.length)+1"
        :isNew="true"
        @submitStep="submitNewStep"
        v-if="!isDisabled"
    />


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import Step from './components/_step'

export default {
  components: {
    Step
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const newStep = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const submitNewStep = () => {
      props.steps.splice(props.steps.length, 0, newStep.value)
      newStep.value = JSON.parse(JSON.stringify({}))
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      newStep,

      // Computed

      // Methods
      submitNewStep,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>