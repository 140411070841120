<template>
  <b-row
  >
    <b-col md="7">
      <b-card>

        <task-event-tabs
            :task-event="task"
            :displayToDoList="true"
        />

      </b-card>
    </b-col>

    <b-col md="5">

      <b-list-group>
<!--        <list-workflow :task="task"/>-->

        <list-deadline :task="task"/>

        <list-repetiton :task="task"/>
      </b-list-group>


    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import ListWorkflow from './components/_list_workflow'
import ListDeadline from './components/_list_deadline'
import ListRepetiton from './components/_list_repetition'
import TaskEventTabs from '../taskEventTabs/TaskEventTabs'

export default {
  components: {
    ListWorkflow,
    ListDeadline,
    ListRepetiton,
    TaskEventTabs
  },
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>