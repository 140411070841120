<template>
  <b-row>
    <b-col :md="taskEvent.workflow==null?12:6">
      <field-select
          :model.sync="taskEvent.workflow"
          :name="'workflow'"
          :options="workflows"
          label="_display"
          :disabled="$can('manage', 'all')?false:isDisabled"
          class="w-100"
          @input="resetTaskEventPhase"
      />
    </b-col>

    <b-col md="6"
           v-if="taskEvent.workflow">
      <field-select
          :model.sync="taskEvent.phase"
          :name="'phase'"
          :placeholder="$t('Choose a phase')"
          :options="availablePhases"
          :label="'name'"
          :disabled="$can('manage', 'all')?false:isDisabled"
          class="w-100"
          :key="componentKey"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '../../../../utils/utils'

import useAPI from '@/utils/useAPI'
import FieldSelect from '@/components/input/Select'

export default {
  components: {
    FieldSelect
  },
  props: {
    taskEvent: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workflows } = useAPI()

    const availablePhases = computed(() => {
      if (isObject(props.taskEvent.workflow)) {
        return workflows.value.find(w => w.id == props.taskEvent.workflow.id).phases
      } else if (workflows.value.some(w => w.id == props.taskEvent.workflow)) {
        return workflows.value.find(w => w.id == props.taskEvent.workflow).phases
      } else {
        return []
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetTaskEventPhase = () => {
      props.taskEvent.phase = null
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      workflows,
      availablePhases,

      // Methods
      resetTaskEventPhase,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>